<script>
// import Layout from "../../../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
 import { required ,maxLength,requiredIf} from "vuelidate/lib/validators";
// import { fetchSettings } from "../api";
// import infoStaff from './infoStaff.vue';
// import Infrastructure from './infrastructure.vue';
// import Multiselect from 'vue-multiselect'
  import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import {
  fetchLifebasesApi,
  fetchSuppliersListApi,
  fetchUserStructuresTypesApi,
  /* fetchDirectionsListApi,
  fetchDivisionsListApi,
  fetchDepartmentsListApi,
  fetchServicesListApi, */
  fetchRolesListApi,
} from "@/api/common";
export default {

    // import : { fetchSettings },
    components: {Multiselect },
    import: { fetchSuppliersListApi, 
            fetchUserStructuresTypesApi,
            /* fetchDirectionsListApi,
            fetchDivisionsListApi,
            fetchDepartmentsListApi,
            fetchServicesListApi,  */
            fetchRolesListApi,
            },
    props:{
     selectedUser: null
    },
  data() {
    return {
      suppliers: [],
      users: [],
      bases: [],
      directions: [],
      divisions: [],
      departments: [],
      services: [],
      strList : null,
      strType : [
          {text:'Direction',value:1},
          {text:'Division',value:2},
          {text:'Services',value:3},
          {text:'Service',value:4},
          {text:'Section',value:5},
      ],
      structures : [],
      roles: [],
      userForm: {
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        supplier: "",
        type: "",
        directSuperior: "",
        role: "",
        structureType: "",
        direction: "",
        division: "",
        department: "",
        service: "",
        password: "",
        id_number: "",
        selectedBases:[]
      },
      types: [
        {
          id: "employee",
          name: "Employé SH",
        },

        {
          id: "supplier_staff",
          name: "Employé Prestataire",
        },
      ],
      userStructuresTypes: [],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      disabled : true,
      canDelte : true,
      disabledAdd : false,
      disabledUpdate : true,
      
    };
  },
  validations: {
    userForm: {
      firstName: { required },
      lastName: { required },
      email: { required },
      structureType : { required : false },
      username: { required },
      password:{
        required: requiredIf(function(){
          return this.disabledAdd == true;
        })
      }
    },
  },
 created() {
    this.fetchLifebasesList();
    this.fetchSuppliersList();
    this.fetchUserStructuresTypes();
    // this.fetchDirectionsList();
    // this.fetchDivisionsList();
    // this.fetchDepartmentsList();
    // this.fetchServicesList();
    this.fetchRolesList();
  },
  methods: {
    getStr(){
         var urlStr
        switch (this.strList) {
            case 1:
                urlStr = "/organisation/hierarchy/direction/getDirectionsList";
                break;
            case 2:
                urlStr = "/organisation/hierarchy/division/getDivisionsList";
                break;
            case 3:
                urlStr = "/organisation/hierarchy/departement/getDepartementsList";
                break;
            case 4:
                urlStr = "/organisation/hierarchy/service/getServicesList";
                break;
            case 5:
                urlStr = "/organisation/hierarchy/section/getSectionsList";
                break;
        }
          let loader = this.$loading.show({
                color: '#000000',
                loader: 'spinner',
                width: 64,
                height: 64,
                backgroundColor: '#ffffff',
                opacity: 0.5,
                zIndex: 999,
            })
            this.$http.get(urlStr)
            .then((res) => {
                this.structures = res.data;
                
                loader.hide();
            }).catch((error) => {
                loader.hide();
            this.$toast.error(error.message);
            })
            .finally(() => {});
    },
    addBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledUpdate = false;
      this.disabledUpdate = true;
      this.canDelte       = true;
      this.clearForm();
    },
    duplicateBtn(){
      this.disabled       = false;
      this.disabledAdd    = true;
      this.disabledUpdate = false;
      this.userForm.uuid  ="";
    },
    editBtn(){
      this.disabledAdd    = false;
      this.disabledUpdate = true;
      this.disabled       = false;
    },
    cancelBtn(){
      this.disabled       = true;
      this.disabledAdd    = false;
      this.disabledUpdate = false;
    },
    fetchLifebasesList(){
      fetchLifebasesApi()
        .then((res) => (this.bases = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    /* fetchDirectionsList(){
      fetchDirectionsListApi()
        .then((res) => (this.directions = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchDivisionsList(){
      fetchDivisionsListApi()
        .then((res) => (this.divisions = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchDepartmentsList(){
      fetchDepartmentsListApi()
        .then((res) => (this.departments = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchServicesList(){
      fetchServicesListApi()
        .then((res) => (this.services = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    }, */
    fetchUserStructuresTypes() {
      fetchUserStructuresTypesApi()
        .then((res) => (this.userStructuresTypes = res.data.original.data))
        .catch(() => {})
        .finally(() => {});
    },
    fetchSuppliersList() {
      fetchSuppliersListApi()
        .then((res) => (this.suppliers = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    fetchRolesList(){
      fetchRolesListApi()
      .then((res) => (this.roles = res.data.original.data))
        .catch(() => {})
        .finally(() => {});
    },
    deleteUser() {
      var typeName = this.userForm.firstName+" "+this.userForm.lastName;
      var typeUid  = this.userForm.uuid;
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer l'utlisateur :" + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#f46a6a",
        cancelButtonColor: "#B8CCD7",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/system/users/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                    this.$toast.success(res.data.original.msg);
                  this.$emit('reloadTable',true)
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    formSubmit() {
      this.submitted = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitted = false
        this.$http.post("/system/users/store", this.userForm)
        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              this.$emit('reloadTable',true)

              break;

            case 500:
              this.$toast.error(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
            this.$toast.error(error.message);
        })
        .finally(() => {});
      }
    },
    clearForm(){
        this.userForm.uuid           ="";
        this.userForm.firstName      ="";
        this.userForm.lastName       ="";
        this.userForm.email          ="";
        this.userForm.username       ="";
        this.userForm.supplier       ="";
        // this.userForm.type           ="";
        this.userForm.directSuperior ="";
        this.userForm.role           ="";
        this.userForm.structureType  ="";
        this.userForm.direction      ="";
        this.userForm.division       ="";
        this.userForm.department     ="";
        this.userForm.service        ="";
        this.userForm.selectedBases  ="";
        this.userForm.id_number      ="";
    }
  },

  watch:{
    selectedUser: function(selectedUser){
      this.clearForm();
      this.disabledUpdate = true;
      this.canDelte       = true;
      this.disabledAdd    = false;
      if(selectedUser.length> 0 &&  undefined != selectedUser[0]){
        this.disabledUpdate = false;
        this.canDelte       = false;
        this.userForm.uuid           = selectedUser[0].uuid;
        this.userForm.firstName      = selectedUser[0].first_name;
        this.userForm.lastName       = selectedUser[0].last_name;
        this.userForm.email          = selectedUser[0].email;
        this.userForm.username       = selectedUser[0].username;
        this.userForm.supplier       = selectedUser[0].supplier;
        this.userForm.type           = selectedUser[0].account_typeObj;
        this.userForm.directSuperior = selectedUser[0].directSuperior;
        this.userForm.role           = selectedUser[0].role;
        this.userStructuresTypes     = selectedUser[0].structType;
        // this.userForm.structureType  = selectedUser[0].structType;
        if(selectedUser[0].structure_id){
          var sil = (selectedUser[0].structure_id).length;
           switch (sil) {
               case 3:
                   this.strList = 1;
                   break;
               case 6:
                   this.strList = 2;
                   break;
               case 9:
                   this.strList = 3;
                   break;
               case 12:
                   this.strList = 4;
                   break;
               case 15:
                   this.strList = 5;
                   break;
           
               default:
                   break;
           }
           this.getStr();
           this.userForm.Emp_structure_id     = selectedUser[0].structure_id;
        }
        this.userForm.selectedBases  = selectedUser[0].lifebase;
        this.userForm.id_number      = selectedUser[0].id_number;
      }
    }  
  },
  mounted(){

},


}
</script>
<style >
  .finish-button{
    background-color:#43A047 !important;
    border-color: #43A047 !important;
  }
  #StickyUF {
    position: fixed;
    right: 0;
    top: 50%;
    width: 8em;
    margin-top: -2.5em;
    z-index: 999;
    list-style: none;
  }
  .rounded-pillUF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 50% !important;
  }
  .btn-addUF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #044A72 ;
  }
  .btn-duplicateUF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #097EA9 ;
  }
  .btn-editUF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #1CA68D ;
  }
  .btn-deleteUF #dropdown-dropleft__BV_toggle_{
     border-radius: 50% !important;
  }
  .btn-deleteUF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #D61337;
  }
  .btn-uploadUF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #2A5769 ;
  }
  .btn-downloadUF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #21BF92;
  }
  .btn-printUF{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background-color: #B8CCD7 ;
  }
</style>

<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title  pb-2">
              Details
            </h4>
            <ul id="StickyUF">
                <li>
                  <button type="button" @click="addBtn" :disabled="disabledAdd" class="btn btn-addUF rounded-pillUF float-right mr-1 mt-1">
                  <i class="fas fa-plus text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button" :disabled="disabledAdd" @click="duplicateBtn" class="btn btn-duplicateUF rounded-pillUF float-right mr-1 mt-1">
                  <i class="far fa-copy text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button" :disabled="disabledUpdate" @click="editBtn" class="btn btn-editUF rounded-pillUF float-right mr-1 mt-1">
                  <i class="far fa-edit text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button" :disabled="canDelte" @click="deleteUser" class="btn btn-deleteUF rounded-pillUF float-right mr-1 mt-1">
                   <i class="far fa-trash-alt text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button"  class="d-none btn btn-uploadUF rounded-pillUF float-right mr-1 mt-1">
                  <i class="fas fa-file-upload  text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button"  class="d-none btn btn-downloadUF rounded-pillUF float-right mr-1 mt-1">
                  <i class="fas fa-file-download text-white"></i>
                  </button>
                </li>
                <li>
                  <button type="button"  class="d-none btn btn-printUF rounded-pillUF float-right mr-1 mt-1">
                  <i class="fas fa-print"></i>
                  </button>
                </li>
            </ul>
            <fieldset :disabled="disabled">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-email-input">Type d'utilisateur *</label>
                    <multiselect
                      v-model="userForm.type"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="types"
                      placeholder="Select user type"
                      :allow-empty="false"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                    <div
                      v-if="submitted && $v.userForm.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.email.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-md-3" v-if="userForm.type.id == 'supplier_staff'">
                  <div class="form-group">
                    <label for="formrow-email-input">Prestataire *</label>
                    <multiselect
                      v-model="userForm.supplier"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="suppliers"
                      placeholder="Select Supplier"
                      :allow-empty="false"
                    >
                      <template
                        slot="singleSupplier"
                        slot-scope="{ supplier }"
                        >{{ supplier.name }}</template
                      >
                    </multiselect>
                    <div
                      v-if="submitted && $v.userForm.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.email.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-email-input">Nom *</label>
                    <input
                      id="projectname"
                      v-model="userForm.firstName"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.userForm.firstName.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="submitted && $v.userForm.firstName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.firstName.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-email-input">Prénom *</label>
                    <input
                      id="projectname"
                      v-model="userForm.lastName"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.userForm.lastName.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="submitted && $v.userForm.lastName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.lastName.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-email-input">Email *</label>
                    <input
                      id="projectname"
                      v-model="userForm.email"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.userForm.email.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="submitted && $v.userForm.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.email.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-email-input">Nom d'utilisateur *</label>
                    <input
                      id="projectname"
                      v-model="userForm.username"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.userForm.username.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="submitted && $v.userForm.username.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.username.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-email-input">Mot de passe *</label>
                    <input
                      id="projectname"
                      v-model="userForm.password"
                      type="password"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.userForm.password.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="submitted && $v.userForm.password.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.password.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-email-input">Rôle *</label>
                    <multiselect
                      v-model="userForm.role"
                      :searchable="true"
                      track-by="role.id"
                      label="display_name"
                      :options="roles"
                      placeholder="Select user Role"
                      :allow-empty="false"
                    >
                      <template slot="singleRole" slot-scope="{ role }">{{
                        role.display_name
                      }}</template>
                    </multiselect>
                    <div
                      v-if="submitted && $v.userForm.role.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.role.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>

                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-email-input">N° Employé *</label>
                    <input
                      id="projectname"
                      v-model="userForm.id_number"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': submitted && $v.userForm.id_number.$error,
                      }"
                      placeholder=""
                    />
                    <div
                      v-if="submitted && $v.userForm.id_number.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.id_number.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-4 col-lg-3">
                  <div class="form-group">
                    <label for="formrow-email-input">Base de vie *</label>
                    <Multiselect
                      v-model="userForm.selectedBases"
                      :options="bases"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="Selectionner une ou plusieurs bases"
                      label="name"
                      track-by="id"
                      :preselect-first="true"
                    />
                    <div
                      v-if="submitted && $v.userForm.selectedBases.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.selectedBases.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-sm-12 col-lg-3 col-md-4 mt-2">
                    <label for="formrow-inputCity">Type de Structure *</label>
    
                    <select class="form-control" @change="getStr()" v-model="strList" >
                        <option v-for="strT in strType" :key="strT.value" :value="strT.value"> {{strT.text}} </option>
                    </select>
                    <div
                        v-if="submitted && $v.userForm.structureType.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.userForm.structureType.required"
                        >La structure est obligatoire.</span
                        >
                    </div>
                </div>
                <div class="col-sm-12 col-lg-3 col-md-4 mt-2">
                    <label for="formrow-inputCity">Structure *</label>
    
                    <select class="form-control" v-model="userForm.structureType" 
                    @blur="$v.userForm.structureType.$touch"
                    :class="{
                    'is-invalid': submitted && $v.userForm.structureType.$error,
                    }">
                        <option v-for="structure in structures" :key="structure.code" :value="structure.code"> {{structure.name}} </option>
                    </select>
                    <div
                        v-if="submitted && $v.userForm.structureType.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.userForm.structureType.required"
                        >La structure est obligatoire.</span
                        >
                    </div>
                </div>
                <div class="col-md-3 d-none">
                  <div class="form-group">
                    <label for="formrow-email-input">Type de Structure *</label>
                    <multiselect
                      v-model="userForm.structureType"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="userStructuresTypes"
                      placeholder="Select user structure"
                      :allow-empty="false"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                    <div
                      v-if="submitted && $v.userForm.structureType.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.structureType.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div>
                <!-- <div class="col-md-3">
                  <div class="form-group">
                    <label for="formrow-email-input"
                      >Supérieur hiérarchique *</label
                    >
                    <multiselect
                      v-model="userForm.type"
                      :searchable="true"
                      track-by="id"
                      label="name"
                      :options="types"
                      placeholder="Select user type"
                      :allow-empty="false"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                    <div
                      v-if="submitted && $v.userForm.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.userForm.email.required"
                        >La référence du contrat est obligatoire.</span
                      >
                    </div>
                  </div>
                </div> -->

                
              </div>

              <div>
                <button
                  class="btn btn-primary"
                  :disabled="$v.userForm.$invalid"
                  type="submit"
                >
                  Enregistrer
                </button>
              </div>
            </form>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    <!-- </Layout> -->
</template>
