var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-lg-12" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("h4", { staticClass: "card-title  pb-2" }, [_vm._v(" Details ")]),
          _c("ul", { attrs: { id: "StickyUF" } }, [
            _c("li", [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-addUF rounded-pillUF float-right mr-1 mt-1",
                  attrs: { type: "button", disabled: _vm.disabledAdd },
                  on: { click: _vm.addBtn }
                },
                [_c("i", { staticClass: "fas fa-plus text-white" })]
              )
            ]),
            _c("li", [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-duplicateUF rounded-pillUF float-right mr-1 mt-1",
                  attrs: { type: "button", disabled: _vm.disabledAdd },
                  on: { click: _vm.duplicateBtn }
                },
                [_c("i", { staticClass: "far fa-copy text-white" })]
              )
            ]),
            _c("li", [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-editUF rounded-pillUF float-right mr-1 mt-1",
                  attrs: { type: "button", disabled: _vm.disabledUpdate },
                  on: { click: _vm.editBtn }
                },
                [_c("i", { staticClass: "far fa-edit text-white" })]
              )
            ]),
            _c("li", [
              _c(
                "button",
                {
                  staticClass:
                    "btn btn-deleteUF rounded-pillUF float-right mr-1 mt-1",
                  attrs: { type: "button", disabled: _vm.canDelte },
                  on: { click: _vm.deleteUser }
                },
                [_c("i", { staticClass: "far fa-trash-alt text-white" })]
              )
            ]),
            _vm._m(0),
            _vm._m(1),
            _vm._m(2)
          ]),
          _c("fieldset", { attrs: { disabled: _vm.disabled } }, [
            _c(
              "form",
              {
                staticClass: "needs-validation",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.formSubmit($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Type d'utilisateur *")
                        ]),
                        _c("multiselect", {
                          attrs: {
                            searchable: true,
                            "track-by": "id",
                            label: "name",
                            options: _vm.types,
                            placeholder: "Select user type",
                            "allow-empty": false
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "singleType",
                              fn: function(ref) {
                                var type = ref.type
                                return [_vm._v(_vm._s(type.name))]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.userForm.type,
                            callback: function($$v) {
                              _vm.$set(_vm.userForm, "type", $$v)
                            },
                            expression: "userForm.type"
                          }
                        }),
                        _vm.submitted && _vm.$v.userForm.email.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.userForm.email.required
                                ? _c("span", [
                                    _vm._v(
                                      "La référence du contrat est obligatoire."
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _vm.userForm.type.id == "supplier_staff"
                    ? _c("div", { staticClass: "col-md-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "formrow-email-input" } },
                              [_vm._v("Prestataire *")]
                            ),
                            _c("multiselect", {
                              attrs: {
                                searchable: true,
                                "track-by": "id",
                                label: "name",
                                options: _vm.suppliers,
                                placeholder: "Select Supplier",
                                "allow-empty": false
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "singleSupplier",
                                    fn: function(ref) {
                                      var supplier = ref.supplier
                                      return [_vm._v(_vm._s(supplier.name))]
                                    }
                                  }
                                ],
                                null,
                                false,
                                4237759933
                              ),
                              model: {
                                value: _vm.userForm.supplier,
                                callback: function($$v) {
                                  _vm.$set(_vm.userForm, "supplier", $$v)
                                },
                                expression: "userForm.supplier"
                              }
                            }),
                            _vm.submitted && _vm.$v.userForm.email.$error
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  !_vm.$v.userForm.email.required
                                    ? _c("span", [
                                        _vm._v(
                                          "La référence du contrat est obligatoire."
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "formrow-email-input" } }, [
                        _vm._v("Nom *")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userForm.firstName,
                            expression: "userForm.firstName"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid":
                            _vm.submitted && _vm.$v.userForm.firstName.$error
                        },
                        attrs: {
                          id: "projectname",
                          type: "text",
                          placeholder: ""
                        },
                        domProps: { value: _vm.userForm.firstName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.userForm,
                              "firstName",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm.submitted && _vm.$v.userForm.firstName.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.userForm.firstName.required
                              ? _c("span", [
                                  _vm._v(
                                    "La référence du contrat est obligatoire."
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "formrow-email-input" } }, [
                        _vm._v("Prénom *")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userForm.lastName,
                            expression: "userForm.lastName"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid":
                            _vm.submitted && _vm.$v.userForm.lastName.$error
                        },
                        attrs: {
                          id: "projectname",
                          type: "text",
                          placeholder: ""
                        },
                        domProps: { value: _vm.userForm.lastName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.userForm,
                              "lastName",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm.submitted && _vm.$v.userForm.lastName.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.userForm.lastName.required
                              ? _c("span", [
                                  _vm._v(
                                    "La référence du contrat est obligatoire."
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "formrow-email-input" } }, [
                        _vm._v("Email *")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userForm.email,
                            expression: "userForm.email"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid":
                            _vm.submitted && _vm.$v.userForm.email.$error
                        },
                        attrs: {
                          id: "projectname",
                          type: "text",
                          placeholder: ""
                        },
                        domProps: { value: _vm.userForm.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.userForm, "email", $event.target.value)
                          }
                        }
                      }),
                      _vm.submitted && _vm.$v.userForm.email.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.userForm.email.required
                              ? _c("span", [
                                  _vm._v(
                                    "La référence du contrat est obligatoire."
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "formrow-email-input" } }, [
                        _vm._v("Nom d'utilisateur *")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userForm.username,
                            expression: "userForm.username"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid":
                            _vm.submitted && _vm.$v.userForm.username.$error
                        },
                        attrs: {
                          id: "projectname",
                          type: "text",
                          placeholder: ""
                        },
                        domProps: { value: _vm.userForm.username },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.userForm,
                              "username",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm.submitted && _vm.$v.userForm.username.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.userForm.username.required
                              ? _c("span", [
                                  _vm._v(
                                    "La référence du contrat est obligatoire."
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "formrow-email-input" } }, [
                        _vm._v("Mot de passe *")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userForm.password,
                            expression: "userForm.password"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid":
                            _vm.submitted && _vm.$v.userForm.password.$error
                        },
                        attrs: {
                          id: "projectname",
                          type: "password",
                          placeholder: ""
                        },
                        domProps: { value: _vm.userForm.password },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.userForm,
                              "password",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm.submitted && _vm.$v.userForm.password.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.userForm.password.required
                              ? _c("span", [
                                  _vm._v(
                                    "La référence du contrat est obligatoire."
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Rôle *")
                        ]),
                        _c("multiselect", {
                          attrs: {
                            searchable: true,
                            "track-by": "role.id",
                            label: "display_name",
                            options: _vm.roles,
                            placeholder: "Select user Role",
                            "allow-empty": false
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "singleRole",
                              fn: function(ref) {
                                var role = ref.role
                                return [_vm._v(_vm._s(role.display_name))]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.userForm.role,
                            callback: function($$v) {
                              _vm.$set(_vm.userForm, "role", $$v)
                            },
                            expression: "userForm.role"
                          }
                        }),
                        _vm.submitted && _vm.$v.userForm.role.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.userForm.role.required
                                ? _c("span", [
                                    _vm._v(
                                      "La référence du contrat est obligatoire."
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-3" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "formrow-email-input" } }, [
                        _vm._v("N° Employé *")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.userForm.id_number,
                            expression: "userForm.id_number"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "is-invalid":
                            _vm.submitted && _vm.$v.userForm.id_number.$error
                        },
                        attrs: {
                          id: "projectname",
                          type: "text",
                          placeholder: ""
                        },
                        domProps: { value: _vm.userForm.id_number },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.userForm,
                              "id_number",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm.submitted && _vm.$v.userForm.id_number.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.userForm.id_number.required
                              ? _c("span", [
                                  _vm._v(
                                    "La référence du contrat est obligatoire."
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _c("div", { staticClass: "col-xs-12 col-md-4 col-lg-3" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Base de vie *")
                        ]),
                        _c("Multiselect", {
                          attrs: {
                            options: _vm.bases,
                            multiple: true,
                            "close-on-select": false,
                            "clear-on-select": false,
                            "preserve-search": true,
                            placeholder: "Selectionner une ou plusieurs bases",
                            label: "name",
                            "track-by": "id",
                            "preselect-first": true
                          },
                          model: {
                            value: _vm.userForm.selectedBases,
                            callback: function($$v) {
                              _vm.$set(_vm.userForm, "selectedBases", $$v)
                            },
                            expression: "userForm.selectedBases"
                          }
                        }),
                        _vm.submitted && _vm.$v.userForm.selectedBases.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.userForm.selectedBases.required
                                ? _c("span", [
                                    _vm._v(
                                      "La référence du contrat est obligatoire."
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ]),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-sm-12 col-lg-3 col-md-4 mt-2" },
                    [
                      _c("label", { attrs: { for: "formrow-inputCity" } }, [
                        _vm._v("Type de Structure *")
                      ]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.strList,
                              expression: "strList"
                            }
                          ],
                          staticClass: "form-control",
                          on: {
                            change: [
                              function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.strList = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              function($event) {
                                return _vm.getStr()
                              }
                            ]
                          }
                        },
                        _vm._l(_vm.strType, function(strT) {
                          return _c(
                            "option",
                            {
                              key: strT.value,
                              domProps: { value: strT.value }
                            },
                            [_vm._v(" " + _vm._s(strT.text) + " ")]
                          )
                        }),
                        0
                      ),
                      _vm.submitted && _vm.$v.userForm.structureType.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.userForm.structureType.required
                              ? _c("span", [
                                  _vm._v("La structure est obligatoire.")
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-12 col-lg-3 col-md-4 mt-2" },
                    [
                      _c("label", { attrs: { for: "formrow-inputCity" } }, [
                        _vm._v("Structure *")
                      ]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.userForm.structureType,
                              expression: "userForm.structureType"
                            }
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid":
                              _vm.submitted &&
                              _vm.$v.userForm.structureType.$error
                          },
                          on: {
                            blur: _vm.$v.userForm.structureType.$touch,
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.userForm,
                                "structureType",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.structures, function(structure) {
                          return _c(
                            "option",
                            {
                              key: structure.code,
                              domProps: { value: structure.code }
                            },
                            [_vm._v(" " + _vm._s(structure.name) + " ")]
                          )
                        }),
                        0
                      ),
                      _vm.submitted && _vm.$v.userForm.structureType.$error
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            !_vm.$v.userForm.structureType.required
                              ? _c("span", [
                                  _vm._v("La structure est obligatoire.")
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c("div", { staticClass: "col-md-3 d-none" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { attrs: { for: "formrow-email-input" } }, [
                          _vm._v("Type de Structure *")
                        ]),
                        _c("multiselect", {
                          attrs: {
                            searchable: true,
                            "track-by": "id",
                            label: "name",
                            options: _vm.userStructuresTypes,
                            placeholder: "Select user structure",
                            "allow-empty": false
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "singleType",
                              fn: function(ref) {
                                var type = ref.type
                                return [_vm._v(_vm._s(type.name))]
                              }
                            }
                          ]),
                          model: {
                            value: _vm.userForm.structureType,
                            callback: function($$v) {
                              _vm.$set(_vm.userForm, "structureType", $$v)
                            },
                            expression: "userForm.structureType"
                          }
                        }),
                        _vm.submitted && _vm.$v.userForm.structureType.$error
                          ? _c("div", { staticClass: "invalid-feedback" }, [
                              !_vm.$v.userForm.structureType.required
                                ? _c("span", [
                                    _vm._v(
                                      "La référence du contrat est obligatoire."
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ]),
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        disabled: _vm.$v.userForm.$invalid,
                        type: "submit"
                      }
                    },
                    [_vm._v(" Enregistrer ")]
                  )
                ])
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "button",
        {
          staticClass:
            "d-none btn btn-uploadUF rounded-pillUF float-right mr-1 mt-1",
          attrs: { type: "button" }
        },
        [_c("i", { staticClass: "fas fa-file-upload  text-white" })]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "button",
        {
          staticClass:
            "d-none btn btn-downloadUF rounded-pillUF float-right mr-1 mt-1",
          attrs: { type: "button" }
        },
        [_c("i", { staticClass: "fas fa-file-download text-white" })]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "button",
        {
          staticClass:
            "d-none btn btn-printUF rounded-pillUF float-right mr-1 mt-1",
          attrs: { type: "button" }
        },
        [_c("i", { staticClass: "fas fa-print" })]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }